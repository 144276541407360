<template>
  <div class="main-content">
    <h3 class="marginBottom20">系统日志</h3>
    <div>
      <div class="flex flex-wrap align-items marginBottom20">
        <div class="flex align-items marginRight10">
          <span class="span marginRight10">系统模块: </span>
          <div class="width200">
            <el-input
              v-model="searchData.menuStr"
              placeholder="请输入系统模块"
              maxlength="20"
              @keyup.enter.native="handleSearch"
            ></el-input>
          </div>
        </div>
        <div class="flex align-items marginRight10">
          <span class="span marginRight10">操作人员: </span>
          <div class="width200">
            <el-input
              v-model="searchData.username"
              placeholder="请输入操作人员"
              maxlength="20"
              @keyup.enter.native="handleSearch"
            ></el-input>
          </div>
        </div>
        <div class="flex align-items marginRight10 marginBottom10">
          <span class="span marginRight10">类型: </span>
          <div class="width150">
            <el-select
              v-model="searchData.operateType"
              clearable
              @change="handleSearch"
            >
              <el-option
                value=""
                label="请选择"
              ></el-option>
              <el-option
                :value="1"
                label="新增"
              ></el-option>
              <el-option
                :value="2"
                label="修改"
              ></el-option>
              <el-option
                :value="3"
                label="删除"
              ></el-option>
              <el-option
                :value="4"
                label="导出"
              ></el-option>
              <el-option
                :value="5"
                label="导入"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="flex align-items marginRight10 marginBottom10">
          <span class="span marginRight10">状态: </span>
          <div class="width150">
            <el-select
              v-model="searchData.state"
              clearable
              @change="handleSearch"
            >
              <el-option
                value=""
                label="请选择"
              ></el-option>
              <el-option
                :value="1"
                label="成功"
              ></el-option>
              <el-option
                :value="2"
                label="失败"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="flex align-items marginRight10 marginBottom10">
          <span class="span marginRight10">操作时间 : </span>
          <div class="width300">
            <el-date-picker
              class="width300"
              v-model="searchData.createTime"
              type="daterange"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="handleSearch"
            >
            </el-date-picker>
          </div>
        </div>

        <div class="flex align-items marginRight10 marginBottom10">
          <el-button
            size="medium"
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
          >查询</el-button>
        </div>

        <div class="flex align-items marginRight10 marginBottom10">
          <el-button
            size="medium"
            type="info"
            icon="el-icon-refresh-right"
            @click="handleReset"
          >重置</el-button>
        </div>

        <div class="flex align-items marginRight10 marginBottom10">
          <el-button
            size="medium"
            type="danger"
            icon="el-icon-delete"
            @click="handleDelete"
          >删除</el-button>
        </div>

        <div class="flex align-items marginRight10 marginBottom10">
          <el-button
            size="medium"
            type="warning"
            icon="el-icon-download"
            @click="handleExport"
          >导出</el-button>
        </div>
      </div>
    </div>
    <avue-crud
      :data="tableData"
      :option="option"
      ref="crud"
      :table-loading="showLoading"
      :page.sync="page"
      @sort-change="sortChange"
      @on-load="onLoad"
      @selection-change="selectionChange"
    >
      <!-- <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">
            <div class="flex align-items marginRight10">
              <span class="span marginRight10">系统模块: </span>
              <div class="width200">
                <el-input
                  v-model="searchData.menuStr"
                  placeholder="请输入系统模块"
                  maxlength="20"
                  @keyup.enter.native="handleSearch"
                ></el-input>
              </div>
            </div>
            <div class="flex align-items marginRight10">
              <span class="span marginRight10">操作人员: </span>
              <div class="width200">
                <el-input
                  v-model="searchData.username"
                  placeholder="请输入操作人员"
                  maxlength="20"
                  @keyup.enter.native="handleSearch"
                ></el-input>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">类型: </span>
              <div class="width150">
                <el-select
                  v-model="searchData.operateType"
                  clearable
                  @change="handleSearch"
                >
                  <el-option value="" label="请选择"></el-option>
                  <el-option :value="1" label="新增"></el-option>
                  <el-option :value="2" label="修改"></el-option>
                  <el-option :value="3" label="删除"></el-option>
                  <el-option :value="4" label="导出"></el-option>
                  <el-option :value="5" label="导入"></el-option>
                </el-select>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">状态: </span>
              <div class="width150">
                <el-select
                  v-model="searchData.state"
                  clearable
                  @change="handleSearch"
                >
                  <el-option value="" label="请选择"></el-option>
                  <el-option :value="1" label="成功"></el-option>
                  <el-option :value="2" label="失败"></el-option>
                </el-select>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">到期时间 : </span>
              <div class="width300">
                <el-date-picker
                  v-model="searchData.createTime"
                  type="daterange"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="handleSearch"
                >
                </el-date-picker>
              </div>
            </div>

            <div class="flex align-items marginRight10">
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                class="marginLeft10 marginBottom10"
                >查询</el-button
              >
            </div>

            <div class="flex align-items marginRight10">
              <el-button
                size="medium"
                type="info"
                icon="el-icon-refresh-right"
                @click="handleReset"
                class="marginLeft10 marginBottom10"
                >重置</el-button
              >
            </div>

            <div class="flex align-items marginRight10">
              <el-button
                size="medium"
                type="danger"
                icon="el-icon-delete"
                @click="handleDelete"
                >删除</el-button
              >
            </div>

            <div class="flex align-items marginRight10">
              <el-button
                size="medium"
                type="warning"
                icon="el-icon-download"
                @click="handleExport"
                >导出</el-button
              >
            </div>
          </div>
        </div>
      </template> -->

      <template
        slot="menu"
        slot-scope="{ row }"
      >
        <el-button
          type="text"
          size="mini"
          icon="el-icon-view"
          @click="handleDetail(row)"
        >详细</el-button>
      </template>

      <template
        slot="operateType"
        slot-scope="{ row }"
      >
        <el-tag
          type="info"
          v-if="
            row.operateType === 1 ||
              row.operateType === 2 ||
              row.operateType === 4 ||
              row.operateType === 5
          "
        >{{
            row.operateType === 1
              ? "新增"
              : row.operateType === 2
              ? "修改"
              : row.operateType === 4
              ? "导出"
              : row.operateType === 5
              ? "导入"
              : ""
          }}</el-tag>
        <el-tag
          type="danger"
          v-if="row.operateType === 3"
        >删除</el-tag>
      </template>

      <template
        slot="state"
        slot-scope="{ row }"
      >
        <el-tag v-if="row.state === 1">成功</el-tag>
        <el-tag
          type="danger"
          v-else
        >失败</el-tag>
      </template>
    </avue-crud>

    <el-dialog
      title="操作日志详细"
      :visible.sync="dialogVisible"
      width="800px"
      :before-close="handleClose"
    >
      <el-descriptions :column="2">
        <el-descriptions-item
          label="操作模块"
          labelStyle="width: 80px;"
        >{{
          dialogValue.menuStr
        }}</el-descriptions-item>
        <el-descriptions-item
          label="请求地址"
          labelStyle="width: 80px;"
        >{{
          dialogValue.url
        }}</el-descriptions-item>
        <el-descriptions-item
          label="登录信息"
          labelStyle="width: 80px;"
        >{{
          dialogValue.username + "/" + dialogValue.ip
        }}</el-descriptions-item>
        <el-descriptions-item
          label="请求方式"
          labelStyle="width: 80px;"
        >{{
          dialogValue.requestMethod
        }}</el-descriptions-item>
        <el-descriptions-item
          label="操作方法"
          :span="2"
          labelStyle="width: 80px;"
        >{{ dialogValue.method }}</el-descriptions-item>
        <el-descriptions-item
          label="请求参数"
          :span="2"
          labelStyle="width: 80px;"
          contentStyle="width: 700px;"
        >{{ dialogValue.requestParams }}</el-descriptions-item>
        <el-descriptions-item
          label="返回参数"
          :span="2"
          labelStyle="width: 80px;"
          contentStyle="width: 700px;"
        >{{ dialogValue.responseParams }}</el-descriptions-item>
        <el-descriptions-item
          label="操作状态"
          labelStyle="width: 80px;"
        >{{
          dialogValue.state === 1 ? "成功" : "失败"
        }}</el-descriptions-item>
        <el-descriptions-item
          label="操作时间"
          labelStyle="width: 80px;"
        >{{
          dialogValue.createTime
        }}</el-descriptions-item>
      </el-descriptions>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { list, deleteNew, view } from "@/api/log/manage";
import qs from "qs";
import dayjs from "dayjs";

export default {
  name: "index",
  data() {
    return {
      dialogVisible: false,
      searchData: {
        username: "",
        state: "",
        operateType: "",
        menuStr: "",
        createTime: "",
        sort: 1,
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        // title: "系统日志",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        indexLabel: "序号", // 索引文字
        defaultSort: { prop: "createTime", order: "descending" },
        size: "small",
        selection: true, // 显示多选框
        reserveSelection: true,
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        tip: false,
        column: [
          {
            label: "日志编号",
            prop: "id",
          },
          {
            label: "系统模块",
            prop: "menuStr",
          },
          {
            label: "操作类型",
            prop: "operateType",
          },
          {
            label: "请求方式",
            prop: "requestMethod",
          },
          {
            label: "操作人",
            prop: "username",
          },
          {
            label: "操作地址",
            prop: "ip",
          },
          {
            label: "操作状态",
            prop: "state",
          },
          {
            label: "操作日期",
            prop: "createTime",
            sortable: "custom",
          },
        ],
      },
      selectionChangeArray: [], // 多选数据
      dialogValue: {},
    };
  },
  created() {
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (searchForm?.path === this.$route.path) {
      this.searchData = searchForm.searchData;
      if (
        searchForm.searchData?.createTimeStart &&
        searchForm.searchData?.createTimeEnd
      ) {
        this.searchData.createTime = [
          searchForm.searchData?.createTimeStart,
          searchForm.searchData?.createTimeEnd,
        ];
      }
    } else {
      localStorage.removeItem("searchForm");
    }
  },
  methods: {
    // 详情弹出关闭
    handleClose() {
      this.dialogVisible = false;
    },
    // 详情
    handleDetail(row) {
      this.dialogVisible = true;
      this.dialogValue = {};
      view({
        id: row.id,
      }).then((res) => {
        if (res.code === 200) {
          this.dialogValue = res.data;
        }
      });
    },
    // 批量选择
    selectionChange(e) {
      this.selectionChangeArray = [];
      if (e.length > 0) {
        e.map((item) => {
          this.selectionChangeArray.push(item.id);
        });
      }
    },
    // 时间排序
    sortChange(e) {
      if (e.order === "ascending") {
        this.searchData.sort = 2;
      } else {
        this.searchData.sort = 1;
      }
      this.onLoad();
    },
    onLoad() {
      this.showLoading = true;
      const searchData = {
        username: this.searchData.username,
        state: this.searchData.state,
        operateType: this.searchData.operateType,
        menuStr: this.searchData.menuStr,
        sort: this.searchData.sort,
      };
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      const searchForm = {
        path: this.$route.path,
        searchData,
      };
      localStorage.setItem("searchForm", JSON.stringify(searchForm));
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },

    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      localStorage.removeItem("searchForm");
      // console.log('重置')
      this.searchData = {
        regionName: "",
        name: "",
        code: "",
        minElectricity: "",
        maxElectricity: "",
        moveState: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 导出
    handleExport() {
      const searchData = {
        username: this.searchData.username,
        state: this.searchData.state,
        operateType: this.searchData.operateType,
        menuStr: this.searchData.menuStr,
        sort: this.searchData.sort,
      };
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      window.open(
        `/api/web/log/manage/export?${qs.stringify(searchData)}`,
        "_self"
      );
    },

    // 批量删除
    handleDelete() {
      console.log("删除", this.selectionChangeArray);
      if (this.selectionChangeArray.length > 0) {
        this.$confirm("确定删除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          deleteNew({
            ids: this.selectionChangeArray,
          }).then((res) => {
            if (res.code === 200) {
              this.$refs.crud.toggleSelection();
              this.selectionChangeArray = [];
              this.$message.success("删除成功!");
              this.onLoad();
            } else {
              this.$message.error(res.msg);
            }
          });
        });
      } else {
        this.$message.error("请勾选表格");
      }
    },
  },
};
</script>

<style scoped></style>
